import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: ${({ width }) => `${width}px`};
  margin: 0 auto;

  @media (max-width: 1279px) {
    margin: ${({ addMargin }) => (addMargin ? '0 auto' : ' 0 2rem')};
  }
`

const Container = ({ children, className, ...otherprops }) => {
  return (
    <Wrapper className={className} {...otherprops}>
      {children}
    </Wrapper>
  )
}

export default Container

Container.defaultProps = {
  className: null,
  width: 1200
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}
