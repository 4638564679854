import React from 'react'

import { PrivacyWrapper, LogoSection, ContentWrapper } from '../assets/GDPRcareerContent/styles'

import Container from '../components/UI/Container'
import Logo from '../components/Logo'

const GDPRcontent = (
  <>
    <h3 id="isPasted">
      <strong>Obavijest o za&scaron;titi osobnih podataka u selekcijskom procesu</strong>
    </h3>
    <p>
      <strong>Voditelj obrade podataka:</strong>
      <br />
      <strong>Rentlio d.o.o.</strong>
      <br />
      Obala kneza Trpimira 2, 23000 Zadar
      <br />
      <a href="mailto:hello@rentl.io" target="_blank" rel="noopener noreferrer">
        hello@rentl.io
      </a>
      <br />
      <br />
      <strong id="isPasted">Primatelji osobnih podataka:</strong>
      <br />
      Rentlio d.o.o. koristi PeopleForce, aplikaciju koju je razvio PeopleForce LTD. (u daljnjem tekstu
      &ldquo;PeopleForce&rdquo;), kao dio na&scaron;eg procesa selekcije.
      <br />
      PeopleForce, djelujući kao Izvr&scaron;itelj obrade podataka, obrađuje sve osobne podatke na na&scaron; zahtjev i
      na temelju na&scaron;ih uputa.
      <br />
      Rentlio i PeopleForce su potpisali Ugovor o obradi osobnih podataka kako bi osigurali i za&scaron;titili
      va&scaron;a prava na privatnost podataka.
      <br />
      <br />
      <strong id="isPasted">Vrste podataka koje prikupljamo:</strong>
      <br />
      Podaci koje prikupljamo uključuju informacije sadržane u va&scaron;em životopisu, motivacijskom pismu, te druge
      dokumente koje ste nam dobrovoljno dostavili, kao i podatke prikupljene tijekom intervjua ili drugih procjena, a
      mogu između ostalog uključivati sljedeće:
    </p>
    <ul>
      <li>Ime i prezime</li>
      <li>Poveznice na dru&scaron;tvene mreže</li>
      <li>Adresu&nbsp;</li>
      <li>E-mail adresu</li>
      <li>Broj telefona</li>
      <li>Sve informacije dostupne u životopisu (CV)</li>
      <li>IP adresu</li>
      <li>Informacije o web pregledniku</li>
    </ul>
    <p>
      <br />
      <strong>Svrha obrade osobnih podataka:</strong>
      <br />
      Va&scaron;e osobne podatke prikupljamo i obrađujemo isključivo u svrhu provođenja selekcijskog procesa za poziciju
      na koju ste se prijavili.&nbsp;
      <br />
      Taj proces može uključivati sljedeće:
    </p>
    <ul>
      <li>Provjeru točnosti unesenih podataka</li>
      <li>Provjeru preporuka&nbsp;</li>
      <li>Procjenu kvalifikacija i vje&scaron;tina potrebnih za obavljanje posla za koji se prijavljujete</li>
      <li>Komunikaciju vezanu uz proces (e-mailovi, telefonski pozivi, SMS poruke)</li>
      <li>Popunjavanje upitnika i testova</li>
      <li>Ispunjavanje zakonskih propisa.</li>
    </ul>
    <p>
      <strong id="isPasted">Sigurnost podataka:</strong>
      <br />
      Poduzeli smo sve tehničke i organizacijske mjere kako bismo osigurali za&scaron;titu va&scaron;ih osobnih podataka
      od neovla&scaron;tenog pristupa, izmjene, ili gubitka. Unutar Rentlija va&scaron;i osobni podaci bit će dostupni
      samo ovla&scaron;tenim zaposlenicima na&scaron;e tvrtke koji su izravno uključeni u selekcijski proces.
      Va&scaron;i podaci neće biti dijeljeni s trećim stranama, osim ako to nije zakonski obvezno ili uz va&scaron;u
      izričitu privolu.
      <br />
      <br />
      <strong>Pravni osnov za obradu podataka:</strong>
      <br />
      Obrada va&scaron;ih osobnih podataka temelji se na sljedećim pravnim osnovama:
    </p>
    <ul>
      <li>Va&scaron;oj privoli koju ste nam dali prijavom na natječaj</li>
      <li>Potrebi za poduzimanjem radnji prije sklapanja ugovora o radu</li>
      <li>
        Na&scaron;em legitimnom interesu za procjenu kandidata u svrhu zapo&scaron;ljavanja, kako bismo osigurali
        najbolje moguće osoblje za na&scaron; tim
      </li>
    </ul>
    <p>
      <strong>Razdoblje čuvanja podataka:</strong>
      <br />
      Va&scaron;i osobni podaci bit će čuvani samo onoliko dugo koliko je potrebno za zavr&scaron;etak selekcijskog
      postupka. U slučaju da niste odabrani za poziciju, zadržat ćemo va&scaron;e podatke u na&scaron;oj bazi za buduće
      prilike najvi&scaron;e 36 mjeseci, osim ako ne zatražite brisanje podataka ranije.
      <br />
      <br />
      <strong>Va&scaron;a prava:</strong>
      <br />U skladu s Općom uredbom o za&scaron;titi podataka (GDPR), imate pravo:
    </p>
    <ul>
      <li>Pristupiti svojim osobnim podacima</li>
      <li>Ispraviti netočne ili nepotpune podatke</li>
      <li>Zatražiti brisanje svojih podataka (&bdquo;pravo na zaborav&rdquo;)</li>
      <li>Ograničiti obradu svojih podataka</li>
      <li>Prenijeti svoje podatke drugom voditelju obrade</li>
      <li>Uložiti prigovor na obradu svojih podataka</li>
      <li>Povuci svoju privolu u bilo kojem trenutku, bez utjecaja na zakonitost obrade prije povlačenja privole</li>
    </ul>
    <p>
      Za ostvarivanje svojih prava ili dodatne informacije, slobodno nas kontaktirajte na:&nbsp;
      <a href="mailto:hello@rentl.io" target="_blank" rel="noopener noreferrer">
        hello@rentl.io
      </a>
      <br />.<br />
      <strong>Pravo na pritužbu:</strong>
      <br />
      Ako smatrate da su va&scaron;a prava povrijeđena, imate pravo podnijeti pritužbu Agenciji za za&scaron;titu
      osobnih podataka (AZOP) ili nadležnom tijelu u EU.
    </p>
  </>
)

const PrivacyTermsContent = () => {
  return (
    <PrivacyWrapper>
      <Container>
        <LogoSection>
          <Logo mode="dark" locale="hr" />
          <h4>Napredna tehnologija u turizmu</h4>
        </LogoSection>
        <ContentWrapper>{GDPRcontent}</ContentWrapper>
      </Container>
    </PrivacyWrapper>
  )
}

export default PrivacyTermsContent
