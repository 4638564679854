import styled from 'styled-components'

export const PrivacyWrapper = styled.div`
  max-width: 980px;
  margin: 0 auto;
`

export const LogoSection = styled.div`
  padding: 5rem 0;
  text-align: center;

  border-bottom: 1px solid #adadad;

  img {
    width: 400px;
  }

  h4 {
    color: #706f6f;
    margin-top: 1rem;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
  }

  @media only screen and (max-width: 425px) {
    img {
      width: 300px;
    }
  }
`

export const ContentWrapper = styled.div`
  font-size: 1.14rem;
  line-height: 1.85rem;
  padding: 2rem 0;

  h1 {
    color: #0d3151;
    font-family: 'Barlow';
    font-size: 3.85rem;
    font-weight: 800;
    line-height: 5.14rem;
    max-width: 780px;
    margin: 3rem auto;
  }

  h2 {
    color: #0d3151;
    font-family: 'Barlow';
    font-size: 2.85rem;
    font-weight: 800;
    line-height: 3rem;
    max-width: 780px;
    margin: 3rem auto;
  }

  h3 {
    color: #0d3151;
    font-family: 'Barlow';
    font-size: 1.85rem;
    font-weight: 800;
    line-height: 2.2rem;
    max-width: 780px;
    margin: 3rem auto;
  }

  h4 {
    color: #0d3151;
    font-family: 'Barlow';
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 2rem;
    max-width: 780px;
    margin: 3rem auto;
  }

  h5 {
    color: #0d3151;
    font-family: 'Barlow';
    font-size: 1.4rem;
    font-weight: 800;
    line-height: 1.8rem;
    max-width: 780px;
    margin: 3rem auto;
  }

  h6 {
    color: #0d3151;
    font-family: 'Barlow';
    font-size: 1.2rem;
    font-weight: 800;
    line-height: 1.4rem;
    max-width: 780px;
    margin: 3rem auto;
  }

  p {
    color: #706f6f;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    max-width: 780px;
    margin: 2rem auto;
  }

  a {
    color: #1d9ed9;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    list-style: disc;
    max-width: 780px;
    margin: 2rem auto;
    padding-left: 3rem;

    li {
      color: #706f6f;
      padding-left: 2.5rem;
      font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    }
  }

  @media only screen and (max-width: 425px) {
    h1 {
      font-size: 2.85rem;
      line-height: 3.5rem;
      margin: 1rem auto;
    }

    h3 {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    ul {
      padding-left: 2rem;

      li {
        padding-left: 0.5rem;
      }
    }
  }
`
